.navbar-color {
  /* background-color: #e3f2fd; */
  background-color: rgb(104, 100, 100);
  /* background-color: #fd5f00; */
}

.navbar-background {
  /* background-color: cornsilk; */
}

.nav-logo {
  position: relative;
  right: px;
  height: 50px;
  border-radius: 8px;
}

.nav-contents li .nav-link {
  color: #e3f2fd;
}

.nav-contents li :hover {
  color: aliceblue;
  background-color: #fd5f00;
}

.Active {
  color: aliceblue;
  background-color: #fd5f00;
}

.resetPassword {
  color: white;
  padding: 8px;
  border-radius: 4px;
  background-color: #fd5f00;
}

.forgotPassword {
  color: white;
  padding: 8px;
  border-radius: 4px;
  background-color: #fd5f00;
}

.logout {
  color: white;
  padding: 8px;
  border-radius: 4px;
  background-color: #fd5f00;
}

.login {
  color: white;
  padding: 8px;
  border-radius: 4px;
  background-color: #fd5f00;
}

#login-section {
  /* background-color: #508bfc; */
  background-color: rgb(248, 246, 246);
  /* background-color: rgb(26, 23, 13); */
  min-height: 800px;
}

#login-card {
  border-radius: 2rem;
  margin-top: 60px;
  background-color: rgb(104, 100, 100);
}

#floatingSelect-bmc {
  width: 20rem;
  /* position: relative;
    left: 100px; */
}

#floatingSelect-listType {
  width: 29rem;
  /* position: relative;
    left: 100px; */
}

#floatingSelect-connection {
  width: 20rem;
  /* position: relative;
    bottom: 74px;
    left: 800px; */
}

#show-graph-button {
  margin-left: 45px;
  /* position: relative;
    bottom: 70px;
    left: 550px; */
  /* background-color: rgb(70, 233, 111); */
  background-color: #fd5f00;
  border-color: #fd5f00;
  color: #ffffff;
}

#show-graph-button:hover {
  background-color: rgb(247, 166, 16);
  border-color: rgb(247, 166, 16);
  color: #e3f2fd;
}

.graph {
  background-color: rgb(104, 100, 100);
  /* background-color: #fd5f00; */
  border-radius: 45px;
}

.displayGraph {
  background-color: white;
  border-radius: 25px;
}

.tableGraphs {
  background-color: white;
  border-radius: 20px;
}

.graph-details {
  border-radius: 45px;
  width: 350px;
}

.contact-form::before {
  background-color: #fd5f00;
}

.contact-form {
  background-color: rgb(104, 100, 100);
  border-radius: 45px;
}

#contact-form-button {
  background-color: #fd5f00;
  color: white;
}
#contact-form-button :hover {
  background-color: white;
  color: #fd5f00;
}

.contact-text {
  color: white;
}

.contact-text-link {
  color: white;
  text-decoration: none;
}

.contact-text-link:hover {
  color: white;
  text-decoration: none;
}

.vl {
  border-left: 4px solid #fd5f00;
  height: 2rem;
}

#analysis-table {
  min-width: 10rem;
}

.chart-size {
  min-width: 22rem;
}

#monthlyDetailsTable {
  /* max-width:50rem; */
}

.copyright {
  background-color: rgb(104, 100, 100);
  color: white;
  /* height: 60px; */
}
